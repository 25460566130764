import React from 'react'
import { Grid } from "@mui/material";
import './PodcastContentOtherEpisodes.scss';
import AudioPlayer from '../../AudioPlayer';
import { myStyles } from './../style';
import clsx from 'clsx';
import Container from '@material-ui/core/Container';

export default function PodcastContentOtherEpisodes({ data, images }) {

  const classes = myStyles(images?.capsulesBackgroundImage);
  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-content-episodes">
        {/* **********************    Hero Image With Text    ********************** */}
        <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
            <p className='episodes-title' locId="otherEpisodesLabel">{data?.otherEpisodesLabel}</p>
          </Grid>
        </Grid>

        <Grid container className='epis-container'>
          <Grid item xl={6} lg={6} md={8} sm={8} xs={11}>
            <div className='capsule-wrap'>
            {/* <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} className={clsx("capsule-img",classes['capsules-bg'])} >&nbsp;</Grid>  */}
            </div>           
            {/* <img src={data?.capsulesBackgroundImage?.file?.url} alt={data?.capsulesBackgroundImage?.description} className='epis-bubble-img' /> */}
          </Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12} tabIndex="0">
          {data?.multiplayer ? <AudioPlayer audioplayer={data?.multiplayer} /> : null}
          </Grid>
         
        </Grid>
      </Grid>
      </Container>
    </>
  )
}
