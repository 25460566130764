class PodcastWordCount {
    static generateTotalContent(element,TotalContent) {
        element.forEach((el) => {
          el?.content.forEach((childElement) => {
            TotalContent = TotalContent + ' ' + childElement?.value?.trim();
          });
        });
        return TotalContent;
      }
    static WordCount(podcastdata) {
        let TotalContent = '';
        if (podcastdata?.paragraphText) {
            TotalContent= this.generateTotalContent(JSON.parse(podcastdata?.paragraphText?.raw).content,TotalContent);
          }
          if (podcastdata?.ourGuestsHeading) {
            TotalContent = TotalContent + ' ' + podcastdata?.ourGuestsHeading;
          }
          if (podcastdata?.ourHostsHeading) {
            TotalContent = TotalContent + ' ' + podcastdata?.ourHostsHeading;
          }
          if (podcastdata?.ourGuest) {
            podcastdata?.ourGuest?.forEach((og) => {
              TotalContent = TotalContent + ' ' + og.name;
              TotalContent=this.generateTotalContent(JSON.parse(og?.description?.raw).content,TotalContent);
            });
          }
          if (podcastdata?.ourHost) {
            podcastdata?.ourHost?.forEach((oh) => {
              TotalContent = TotalContent + ' ' + oh.name;
              TotalContent=this.generateTotalContent(JSON.parse(oh?.description?.raw).content,TotalContent);
            });
          }
          if (podcastdata?.otherEpisodesLabel) {
            TotalContent = TotalContent + ' ' + podcastdata?.otherEpisodesLabel;
          }
          return TotalContent;
    } 
    
}
export { PodcastWordCount };