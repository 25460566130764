import { makeStyles } from '@material-ui/core';

export const myStyles = makeStyles(() => ({
  'bg-donut': (DonutImg) => ({
    background: `url(${DonutImg?.file?.url}?fm=webp&q=100) no-repeat`,
  }),
  'bg-dot-matrix': (dotMatrixBackgroundImage) => ({
    '&:after': {
      backgroundImage: `url(${dotMatrixBackgroundImage?.file?.url}?fm=webp&q=100)`,
    }}),

    'capsules-bg': (data) => ({
      background: `url(${data?.file?.url}?fm=webp&q=100) 0 0 no-repeat`,
    }),
}));