import React from 'react';
import Grid from '@material-ui/core/Grid';
import { myStyles } from '../style';
import clsx from 'clsx';
import './PodcastContentHeader.scss';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import AudioPlayer from '../../AudioPlayer';
import {DateFormatUtils} from '../../../utils/date-formatter';
import Container from '@material-ui/core/Container';

export default function PodcastContentHeader({ data, images }) {
  const classes = myStyles(images?.dotMatrixBackgroundImage);
  const {date,month,year}=DateFormatUtils.DateMonthYearFormatter(data?.publishedDate);
  return (
    <>
     <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-content-header-cls">
   
        <Grid container className="Text-with-hero-img">
          <Grid container item xl={5} lg={5} md={12} sm={12} xs={12}>
           <Grid item xl={0} lg={0} md={1} sm={0} xs={0}></Grid>
          <Grid item xl={12} lg={12} md={11} sm={12} xs={12}>
            <div className="header-text">
            <div  className='header-text-div'>
              <Grid className={clsx('header-image', classes['bg-dot-matrix'])} >
                <img
                  src={data?.thumbnailImage?.file?.url+'?fm=webp&q=100'}
                  alt={data?.thumbnailImage?.description}
                  className="podcast-music-img"
                  locId="thumbnailImage"
                />
              </Grid>
                <p className="header-date" locId='resourceType'>{data?.resourceType} / {date} {month} {year}</p>
                <h1 className="header-title" locId="podcastTitle">{data?.podcastTitle}</h1>
             
              </div>
              </div>
            </Grid>
          </Grid>
          <Grid item xl={7} lg={7} md={12} sm={12} xs={12}>
             <div className="header-heroimg" tabIndex="0">
            {data?.singlePlayer ? <AudioPlayer audioplayer={data?.singlePlayer} /> : null}
            </div>
          </Grid>
        </Grid>

        {/* Paragraph */}

        <Grid container className='rich-text-para'>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}></Grid>
          <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <p locId="paragraphText" className='podsParaText'>
            {data?.paragraphText?.raw && (
               documentToReactComponents(JSON.parse(data?.paragraphText?.raw))
               )}
            </p>
          </Grid>
        </Grid>
        
      </Grid>
      </Container>
    </>
  );
}
