import React from 'react';
import clsx from 'clsx';
import { myStyles } from './style';
import './PodcastContent.scss';
import PodcastContentHeader from './PodcastContentHeader';
import PodcastContentOurHostGuest from './PodcastContentOurHostGuest';
import PodcastContentOtherEpisodes from './PodcastContentOtherEpisodes';
import PodcastContentFeed from './PodcastContentFeed';
import CategorySocialMedia from '../CategorySocialMedia';
import {PodcastWordCount} from './PodcastWordCount';
import wordCountProvider from '../word-count/word-count';
export default function PodcastContent({ podcastdata, bucketname, podcastslug, basePath, images, podcastLandingUrl,location }) {
  const classes = myStyles(images?.capsulesBackgroundImage);
  const classes1  = myStyles(images?.podcastDonutBackgroundImage)
  const path = podcastslug;
  const TotalContent=PodcastWordCount.WordCount(podcastdata);
  const wordCount = wordCountProvider(TotalContent);
  return (
    <>
      <div className="podcast-content-main" id={podcastdata?.entryTitle}>
        <div className={clsx('bg-donuts-podcast-content', classes1['bg-donut'])}>
          <PodcastContentHeader data={podcastdata} images={images}/>
          
          <PodcastContentOurHostGuest data={podcastdata} images={images}/>
          
          <PodcastContentFeed data={podcastdata} images={images}/>
          <div className={clsx('capsules-bg', classes['capsules-bg'])}>
          {podcastdata?.multiplayer ? <PodcastContentOtherEpisodes data={podcastdata}  images={images}/> : null}
          </div>
         <div className="category-social-media-padding">
          <CategorySocialMedia
            data={podcastdata}
            bucketname={bucketname}
            path={path}
            type={process.env.GATSBY_CARTUS_PODCAST}
            wordCount={wordCount}
            title={podcastdata?.podcastTitle}
            landingUrl={podcastLandingUrl}
            basePath={basePath}
            images={images}
            location={location}
          />
          </div>
        </div>
      </div>
    </>
  );
}
