import React from 'react'
import { Grid } from "@material-ui/core";
import { Link } from 'gatsby';
import './PodcastContentFeed.scss'
import Container from '@material-ui/core/Container';

export default function PodcastContentFeed({ data, images }) {
    const imageOptimum = '?fm=webp&q=100';
    return (
        <>
        <Container maxWidth="xl" className='container-padding-none'>
            <Grid container className='podcast-content-feeds'>
                {/* Feeds Starts */}
                
                <Grid item xl={1} lg={1} md={2} sm={2} xs={2}>
                  <div className='feeds-container'>
                    <p className='feeds-main-txt' locId="feedsTitle">{data?.feedsTitle}</p>
                  </div>
                </Grid>
                <Grid container className='feeds-image' item xl={11} lg={5} md={7} sm={10} xs={9}>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={data?.applePodcastsLink} role="link" tabIndex="0" target="_blank" locId="applePodcastsLink">
                            <img  src={images?.applePodcasts?.file?.url+imageOptimum} alt={images?.applePodcasts?.description} locId="applePodcasts" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={data?.spotifyLink} role="link" tabIndex="0" target="_blank" locId="spotifyLink">
                            <img src={images?.spotify?.file?.url+imageOptimum} alt={images?.spotify?.description} locId="spotify" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={data?.amazonMusicLink} role="link" tabIndex="0" target="_blank" locId="amazonMusicLink">
                            <img src={images?.amazonMusic?.file?.url+imageOptimum} alt={images?.amazonMusic?.description} locId="amazonMusic" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={data?.googlePodcastsLink} role="link" tabIndex="0" target="_blank" locId="googlePodcastsLink">
                            <img src={images?.googlePodcasts?.file?.url+imageOptimum} alt={images?.googlePodcasts?.description} locId="googlePodcasts" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={data?.iHeartRadioLink} role="link" tabIndex="0" target="_blank" locId="iHeartRadioLink">
                            <img src={images?.iHeartRadio?.file?.url+imageOptimum} alt={images?.iHeartRadio?.description} locId="iHeartRadio" />
                        </Link>
                    </Grid>
                    <Grid item xl={2} lg={4} md={4} sm={4} xs={6}>
                        <Link to={data?.rssFeedLink} role="link" tabIndex="0" target="_blank" locId="rssFeedLink">
                            <img src={images?.rssFeed?.file?.url+imageOptimum} alt={images?.rssFeed?.description} locId="rssFeed" />
                        </Link>
                    </Grid>
                </Grid>
                
                <Grid item xl={0} lg={6} md={4} sm={1} xs={0}></Grid>
                {/* Feeds Ends */}
            </Grid>
            </Container>
        </>
    )
}


