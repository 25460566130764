import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo-component/seo';
// import LoginPage from '../pages/login';
import PodcastContent from '../components/PodcastContent';
export default function PodcastContentPage({location ,pageContext, data }) {
  const [user, setUser] = useState({});
  const allFooterData = [pageContext.appData.footerMenu, pageContext.appData.siteFooter];
  const logoutUser = () => {
    clearSessionCookie();
    localStorage.setItem('user', JSON.stringify(false));
    window.location.reload();
  };
  const clearSessionCookie = () => {
    // the cookie to set is 'SESSION_USER'
    let cookieString = 'SESSION_USER=';
    const pathName = window.location.pathname.split('/')[1].trim();
    // we need to expire this cookie, so lets create a date object that points to yesterday
    const expiryDate = new Date();
    expiryDate.setTime(expiryDate.getTime() - 86400 * 1000);
    // change the cookie parameters and write the cookie back
    cookieString += `; max-age=0; path=/${pathName}`;
    cookieString += `; expires=${expiryDate.toUTCString()}`;
    document.cookie = cookieString;
  };

    return (
    <>
      <Layout
        partnerCode={pageContext.partnerCode}
        navigation={pageContext.appData}
        footer={allFooterData}
        styles={pageContext.theme}
        contactFormDetails={pageContext.appData.contactUsFormDetails}
        siteType={pageContext.appData.siteType}
        logout={logoutUser}
        metadata={pageContext.theme.partnerMetaData}
        searchEnabled={pageContext.appData.enableSearch}
        astEnabled={pageContext.appData.hasAgentSelectionToolAstRequired}
        basePath={pageContext.basePath}
        bucketName={pageContext?.bucketName}
        location={location}
      >
        <SEO
          data={data?.contentfulPodcast?.seoEntry}
          siteurl={pageContext?.siteurl}
          bucketName={pageContext?.bucketName}
          basePath={pageContext.basePath}
          organizationName = {pageContext?.appData?.organizationName}
          organizationLogo = {pageContext?.appData?.organizationLogo?.file?.url}
          organizationLinkedinLink = {pageContext?.appData?.organizationLinkedinLink}
          organizationFacebookLink = {pageContext?.appData?.organizationFacebookLink}
          organizationYoutubeLink = {pageContext?.appData?.organizationYoutubeLink}
          location={location}
          podcastSEOSchema={data?.contentfulPodcast}
        />
        <PodcastContent
          podcastdata={data?.contentfulPodcast}
          bucketname={pageContext?.bucketName}
          podcastslug={data?.contentfulPodcast?.podcastSlug}
          basePath={pageContext.basePath}
          images={data?.contentfulComponentBackgroundImages}
          podcastLandingUrl={data?.contentfulCategoryTagsLandingPage?.slug}
          location={location}
        />
      </Layout>
    </>
  );
}

export const query = graphql`
  query PodcastPageTemplate($podcastSlug: String, $locale: String, $partnerId: String) {
    contentfulCategoryTagsLandingPage{
      entryTitle
      slug
      }
    contentfulPartnerSite(partnerId: {eq: $partnerId}) {
      navigation {
        menus {
          menuLink {
            link {
              ... on ContentfulInternalLink {
                id
                referenceToPage {
                  ... on ContentfulCustomResourceHub {
                    id
                    morePodcasts {
                      slug
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    contentfulComponentBackgroundImages {
      dotMatrixBackgroundImage{
        file {
          url
        }
      }
      podcastDonutBackgroundImage{
        file {
          url
        }
      }
      capsulesBackgroundImage{
        file {
          url
        }
      }
      linkedlnIcon{
        description
        file {
          url
        }
      }
      twitterIcon{
        description
        file {
          url
        }
      }
      facebookIcon{
        description
        file {
          url
        }
      }
      emailIcon{
        description
        file {
          url
        }
      }
      rssFeed{
        description
        file {
          url
        }
      }
      spotify{
        description
        file {
          url
        }
      }
      applePodcasts{
        description
        file {
          url
        }
      }
      amazonMusic{
        description
        file {
          url
        }
      }
      googlePodcasts{
        description
        file {
          url
        }
      }
      iHeartRadio{
        description
        file {
          url
        }
      }
      sys {
        contentType {
          sys {
            type
          }
        }
      }
    }
    contentfulPodcast(podcastSlug: { eq: $podcastSlug }, node_locale: { eq: $locale }) {
      seoEntry {
        pageTitle
        metaDescription {
          metaDescription
        }
        metaKeywords
        metaRobotsTag
        ogType
        ogSiteName
        defaultOgLocale
        altimageTag
        ogImage {
          title
          file {
            url
          }
        }
      }
      sys {
        contentType {
          sys {
            id
          }
        }
      }
      amazonMusicLink
      applePodcastsLink
      singlePlayer {
        raw
      }
      multiplayer {
        raw
      }
      categoryTitle
      feedsTitle
      googlePodcastsLink
      iHeartRadioLink
      otherEpisodesLabel
      ourGuestsHeading
      ourHostsHeading
      paragraphText {
        raw
      }
      podcastSlug
      podcastTitle
      publishedDate(formatString: "YYYY-MM-DD")
      resourceType
      rssFeedLink
      spotifyLink
      tagsTitle
      thumbnailImage {
        description
        title
        file {
          url
        }
      }

      ourGuest {
        ... on ContentfulComponentTextRichTextWithImage {
          name
          image {
            description
            file {
              url
              fileName
            }
            title
          }
          description {
            raw
          }
        }
      }
      ourHost {
        ... on ContentfulComponentTextRichTextWithImage {
          name
          image {
            description
            file {
              url
              fileName
            }
            title
          }
          description {
            raw
          }
        }
      }
      categoryList {
        categoryName
        contentful_id
        entryTitle
      }
      tagList {
        contentful_id
        entryTitle
        tagName
      }
    }
  }
`;
