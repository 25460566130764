import React from 'react'
import { Grid } from "@material-ui/core";
import './PodcastContentOurHostGuest.scss'
import OurGuest from './OurGuest';
import OurHost from './OurHost';
import Container from '@material-ui/core/Container';
export default function PodcastContentOurHostGuest({ data, images }) {

  return (
    <>
    <Container maxWidth="xl" className='container-padding-none'>
      <Grid container className="podcast-content-tiles-cls">
        <OurGuest  items={data} images={images}/>
        <OurHost   items={data} images={images}/>
      </Grid>
    </Container>
    </>
  )
}


