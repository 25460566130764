import React from 'react'
import { Grid } from "@material-ui/core";
import { myStyles } from './../../style';
import clsx from 'clsx';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
export default function OurGuest({ items, images }) {
  const classes = myStyles(images?.dotMatrixBackgroundImage);
  return (
    <>
      <Grid item container xl={12} lg={12} md={12} sm={12} xs={12}>
          <h2 className='tiles-title' locId="ourGuestsHeading">{items?.ourGuestsHeading}</h2>
      </Grid>
      {items?.ourGuest?.map((item, index) => (
        <>
          {index % 2 === 0 ?
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} 
                  className='tiles-section-one' locId="ourGuest">
              <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
                <Grid className='desk-name'>
                <h3 className='sub-title'>{item?.name}</h3>
                </Grid>
                 <p className='tiles-desc-one'>{documentToReactComponents(JSON.parse(item?.description?.raw))}</p>
              </Grid>
              <Grid item container xl={3} lg={3} md={3} sm={12} xs={12}>
                <Grid item xl={12} lg={12} md={12} sm={8} xs={6} className='mob-name'>
                <h3 className='sub-title'>{item?.name}</h3>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={4} xs={6}
                  className={clsx('dot-matrix-img-right', classes['bg-dot-matrix'])}>
                  <img src={item?.image?.file?.url+'?fm=webp&q=100'}
                    alt={item?.image?.description} className='tiles-imgs' />
                </Grid>
              </Grid>
            </Grid>
            :
            <Grid item container xl={12} lg={12} md={12} sm={12} xs={12} locId="ourGuest" className='tiles-two-bottom-padding'>
              <Grid item container xl={3} lg={3} md={3} sm={12} xs={12} className='tiles-section-two'>
                <Grid item xl={12} lg={12} md={12} sm={8} xs={6} className='mob-name'>
                  <h3 className='tiles-two-name'> {item?.name}</h3>
                </Grid>
                <Grid item xl={12} lg={12} md={12} sm={4} xs={6}
                  className={clsx('dot-matrix-img-left', classes['bg-dot-matrix'])} >
                  <img src={item?.image?.file?.url+'?fm=webp&q=100'} alt={item?.image?.description} className='tiles-imgs' />
                </Grid>
              </Grid>
              <Grid item xl={9} lg={9} md={9} sm={12} xs={12} className='desc-two-container'>
                <Grid className='desk-name'>
                <h3 className='sub-title'> {item?.name}</h3>
                </Grid>
                  <p className='tiles-desc-two'>{documentToReactComponents(JSON.parse(item?.description?.raw))}</p>
              </Grid>
            </Grid>
          }
        </>
      ))
      }
    </>
  )
}